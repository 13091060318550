<template>
    <article class="list-reservation-card">
        <a-row :gutter="24" type="flex" justify="space-between" :style="{ flexWrap: 'nowrap' }">
            <a-col flex="120px">
                <app-image :src="record?.object?.previewUrl" width="120px" height="120px" />
                <span class="valign-middle" :style="{
                    fontSize: '1.25rem',
                    zIndex: 999,
                    position: 'absolute',
                    top: '9%',
                    right: !isFromSlots ? '15%' : '15%',
                }" :class="{ 'cursor-none': loadingFavourites }">
                    <loading-outlined v-if="loadingFavourites" class="primary-color-text" />
                    <heart-filled v-else-if="record?.object?.favourite" class="error-color-text clickable-image"
                        @click="changeFavourite(false)" />
                    <heart-outlined v-else style="color: #ffffff;" class="clickable-image" @click="changeFavourite(true)" />
                </span>
            </a-col>
            <a-col flex="auto" :style="{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'space-between',
                paddingTop: '12px',
                paddingBottom: '12px',
            }">
                <a-row :gutter="16" type="flex" justify="space-between"
                    style="flex-wrap: nowrap; justify-content: space-between; ">
                    <a-col style="width: 100%;">

                        <p :style="{

                            marginBottom: '0',
                            color: 'rgba(33, 33, 33, 0.48)',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Aeroport',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '18px',
                        }">
                            {{
                                renderObjectPath(
                                    record?.object?.path || [],
                                    false,
                                    ", "
                                ) || "Верхний уровень"
                            }}
                        </p>
                        <h6>
                            <router-link class="text-color-text" :style="{ marginRight: '0.5rem' }" :to="{
                                name: 'reservationSchedule',
                                params: { id: record?.object?.id },
                            }">
                                {{ record?.object?.name }}
                            </router-link>

                        </h6>


                    </a-col>
                    <a-col class="text-right" v-if="isSlot && !disabled">
                        <a-button class="cancelButton" size="large">
                            <router-link :to="{
                                name: 'reservationSchedule',
                                params: {
                                    id: record?.object?.id,
                                    ...reservationFilters(
                                        record.availableIntervals,
                                        allowReserveTime
                                    ),
                                },
                            }">
                                Забронировать
                            </router-link>
                        </a-button>
                        <a-button size="large" type="primary" v-if="canFinish && !disabled" @click="finishReservation">
                            <template #icon><img src="/images/icons/stop-outlined.svg" alt="=" /></template>
                            Завершить
                        </a-button>
                    </a-col>

                    <a-col v-if="canCancel && !disabled">
                        <a-button class="cancelButton" size="large" @click="cancelReservation">
                            Отменить бронь
                        </a-button>
                    </a-col>
                </a-row>

                <a-row :gutter="16" type="flex" justify="space-between"
                    style="align-items: center; justify-content: space-between;" v-if="reservation">
                    <a-col>
                        <p :style="{
                            marginBottom: '0',
                            color: 'rgba(33, 33, 33, 0.48)',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Aeroport',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '18px',
                        }">
                            Код объекта: {{ record?.object?.code }}
                        </p>

                    </a-col>
                    <a-col v-if="!isFromSlots"> <span class="paragraph-5" style="
                                overflow: hidden;
                                color: rgba(33, 33, 33, 0.48);
                                text-align: right;
                                text-overflow: ellipsis;
                                font-family: Aeroport;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            ">
                            {{
                                moment(
                                    record?.timeFrom,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("DD.MM.YYYY, ")
                            }}

                            {{
                                moment(
                                    record?.timeFrom,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("HH:mm")
                            }}
                            &ndash;
                            {{
                                moment(
                                    record?.timeTo,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("HH:mm")
                            }}
                        </span></a-col>
                </a-row>
            </a-col>
        </a-row>

        <!-- Модалка приглашения участников -->
        <a-modal v-model:visible="invitedPeoplesModalVisible" title="Ваша бронь" width="400px">
            <reservation-preview :name="record?.object?.name" :code="record?.object?.code"
                :preview-url="record?.object?.previewUrl" show-reservation-info :reservation="record" />

            <a-divider />

            <a-form layout="vertical">
                <a-form-item label="Пригласить участников">
                    <invite-user-list :list="invitedUsers" />
                </a-form-item>
            </a-form>

            <template #footer>
                <a-button key="cancel" size="large" @click="invitedPeoplesModalVisible = false">
                    Отмена
                </a-button>
                <a-button key="submit" size="large" type="primary" @click="handleSetInvitedSubmit">
                    Сохранить
                </a-button>
            </template>
        </a-modal>

        <!-- Модалка отмены бронирования -->
        <a-modal v-model:visible="cancelModalVisible" title="Отменить бронирование?" width="400px">
            <reservation-preview :name="record?.object?.name" :code="record?.object?.code"
                :preview-url="record?.object?.previewUrl" show-reservation-info :reservation="record" />

            <template #footer>
                <footer class="ant-modal-confirm-btns">
                    <a-button key="cancel" size="large" @click="cancelModalVisible = false">
                        Не отменять
                    </a-button>
                    <a-button key="submit" size="large" type="danger" @click="handleCancelReservationSubmit">
                        Да, отменить
                    </a-button>
                </footer>
            </template>
        </a-modal>
    </article>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted, unref } from "@vue/runtime-core";
import {
    HeartOutlined,
    HeartFilled,
    LoadingOutlined,
} from "@ant-design/icons-vue";
import ReservationPreview from "@/components/admin/reservation/ReservationPreview";
import InviteUserList from "@/components/admin/reservation/InviteUserList";
import {
    getOneData as getOneObject,
    requestAPI as objectAPI,
} from "@/compositions/objects";
import AppImage from "../shared/ul/AppImage";
import { Modal, notification } from "ant-design-vue";
import {
    requestAPI as reservationAPI,
    getOneData as reservationData,
} from "@/compositions/reservations";
import moment from "moment";

export default {
    name: "ListReservationCard",
    props: {
        reservation: Object,
        object: Object,
        isSlot: Boolean,
        allowReserveTime: Boolean,
        disabled: Boolean,
        isFromSlots: Boolean,
    },
    emits: ["cancel", "finish"],

    setup(props, { emit }) {

        const { reservationFilters } = reservationData();
        const { updateReservation } = reservationAPI();
        const { renderObjectPath } = getOneObject();
        const { addObjectToFavourites, removeObjectFromFavourites } =
            objectAPI();

        const record = ref({});
        const invitedPeoplesModalVisible = ref(false);
        const invitedUsers = ref([]);
        const cancelModalVisible = ref(false);
        const loadingFavourites = ref(false);

        const canCancel = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("cancel") >= 0
        );
        const canFinish = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("finish") >= 0
        );
        const canInvite = computed(
            () =>
                !props.isSlot &&
                (unref(record)?.actions || []).indexOf("invite") >= 0
        );

        onMounted(async () => {
            record.value = props.reservation || {};
            if (props.object) {
                record.value.object = props.object;
            }

        });

        async function changeFavourite(newState) {
            loadingFavourites.value = true;
            try {
                if (newState) {
                    await addObjectToFavourites(record.value?.object?.id);
                } else {
                    await removeObjectFromFavourites(record.value?.object?.id);
                }
                record.value.object.favourite = newState;
            } catch (e) {
                console.error(e);
            } finally {
                loadingFavourites.value = false;
            }
        }

        function changeInvited() {
            invitedUsers.value = [...(unref(record)?.invitedUsers || [])];
            invitedPeoplesModalVisible.value = true;
        }

        async function handleSetInvitedSubmit() {
            try {
                await updateReservation(
                    { invitedUsers: unref(invitedUsers) },
                    unref(record)?.id
                );
                record.value.invitedUsers = unref(invitedUsers);
                notification.success({
                    message: "Успех",
                    description: "Парамеры бронирования сохранены",
                });
                invitedPeoplesModalVisible.value = false;
            } catch (e) {
                console.error(e);
            }
        }

        function cancelReservation() {
            cancelModalVisible.value = true;
        }

        function handleCancelReservationSubmit() {
            emit("cancel", unref(record)?.id);
            cancelModalVisible.value = false;
        }

        function finishReservation() {
            Modal.confirm({
                title: "Завершить бронирование?",
                okText: "Завершить",
                okType: "primary",
                cancelText: "Отмена",
                async onOk() {
                    emit("finish", unref(record)?.id);
                },
                onCancel() { },
            });
        }

        return {
            moment,
            invitedUsers,
            handleSetInvitedSubmit,
            record,
            loadingFavourites,
            invitedPeoplesModalVisible,
            renderObjectPath,
            cancelModalVisible,
            handleCancelReservationSubmit,
            changeFavourite,
            changeInvited,
            cancelReservation,
            finishReservation,
            reservationFilters,
            canCancel,
            canFinish,
            canInvite,
        };
    },
    components: {
        AppImage,
        InviteUserList,
        ReservationPreview,
        HeartOutlined,
        HeartFilled,
        LoadingOutlined,
    },
};
</script>

<style lang="less" scoped>
.cancelButton {
    color: #07a9af !important;
    padding: 8px 16px;
    border-radius: 12px !important;
    border: 1px solid #07a9af !important;
}

.list-reservation-card {
    width: 100%;
    // border-bottom: 1px solid @divider-light;
    padding: 1.25rem 0 1.5rem;
}
</style>
